import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Employee } from "../models/employee";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';
import SearchInput from "components/formInput/SearchInput";

export default class EmployeeStore {
    employees: Employee[] = [];
    employeeMetaData: Omit<PaginatedResult<Employee>, 'data'> | null = null;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state

    constructor() {
        makeAutoObservable(this)
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    // set pagination meta data
    setEmployeeMetaData = (metaData: Omit<PaginatedResult<Employee>, 'data'>) => {
        runInAction(() => {
            this.employeeMetaData = metaData;
        })
    }

    loadEmployees = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = ''
    ) => {
        this.setLoadingInitial(true)
        try {
            const params: SearchParams = {
                pageNumber,
                pageSize,
                keyword
            }
            const { data, ...metaData } = await agent.Employees.searchPaginated(params); 
            runInAction(() => {
                this.employees = data;
            })
            this.setEmployeeMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    searchEmployeesByKeyword = async (
        keyword: string = '',    
        pageNumber: number = 1,
        pageSize: number = 5,) : Promise<Employee[]> => {
        try {
            const params: SearchParams = {
                keyword,
                pageNumber,
                pageSize
            }
            const response = await agent.Employees.search(params);
            if (!response.succeeded) throw new Error(response.messages[0]);
            return response.data;
        } catch (error) {
            console.log(error);
            throw(error);
        }
    }

    createEmployee = async (employee: Employee) => {
        this.setLoading(true)
        try {
            const employeeRequestBody = {
                email: employee.email,
                firstName: employee.firstName,
                lastName: employee.lastName,
                department: employee.department,
                title: employee.title,
                isActive: employee.isActive
            }
            const response = await agent.Employees.create(employeeRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    updateEmployee = async (employee: Employee) => {
        this.setLoading(true)
        try {
            const response = await agent.Employees.update(employee);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    deleteEmployee = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Employees.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }

    downloadImportFileSample = async(): Promise<Blob> => {
        try {
            const response = await agent.Employees.downloadImportFileSample(); 
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    uploadImportFile = async(importFile: FormData) => {
        try {
            this.setLoading(true);
            const response = await agent.Employees.importServicesFromFile(importFile); 
            this.setLoading(false);
            if (!response.succeeded) throw new Error(response.messages[0]);
            return response;
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }
}