import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Policy } from "../models/policy";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';

export default class PolicyStore  {
    policies: Policy[] = [];
    policyMetaData: Omit<PaginatedResult<Policy>, 'data'> | null = null;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state

    constructor() {
        makeAutoObservable(this)
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    // set pagination meta data
    setPolicyMetaData = (metaData: Omit<PaginatedResult<Policy>, 'data'>) => {
        runInAction(() => {
            this.policyMetaData = metaData;
        })
    }

    loadPolicies = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = ''
    ) => {
        this.setLoadingInitial(true)
        try {
            const params: SearchParams = {
                pageNumber,
                pageSize,
                keyword
            }
            const { data, ...metaData } = await agent.Policies.search(params); 
            runInAction(() => {
                this.policies = data;
            })
            this.setPolicyMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    createPolicy = async (policy: Policy) => {
        this.setLoading(true)
        try {
            const policyRequestBody = {
                policyName : policy.policyName,
                thresholdType : policy.thresholdType,
                thresholdValue : policy.thresholdValue,
                emailToNotify : policy.emailToNotify,
                sendEmailNotification : policy.sendEmailNotification,
                policyType : policy.policyType,
            }
            const response = await agent.Policies.create(policyRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    updatePolicy = async (policy: Policy) => {
        this.setLoading(true)
        try {
            const response = await agent.Policies.update(policy);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    deletePolicy = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Policies.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }

    policyTypeOptions = (): any[] => {
        let list: any[] = 
        [
            {description: "Active Services With Zero Usage Over X Days", value: 0, hasThreshold: true},
            {description: "Service Cost More Than X Monthly", value: 1, hasThreshold: true},
            {description: "Data Usage More Than X Monthly (GB)", value: 2, hasThreshold: true},
            {description: "SMS Usage More Than X Monthly", value: 3, hasThreshold: true},
            {description: "Voice Usage More Than X Monthly", value: 4, hasThreshold: true},
            {description: "Variance In Cost By X Monthly", value: 5, hasThreshold: true},
            {description: "Active Services With Inactive Employees", value: 6, hasThreshold: false},
            {description: "Unassigned Active Services Greater Than X", value: 7, hasThreshold: true},
            {description: "Variance In Services By X Monthly", value:8, hasThreshold: true},
            {description: "Service Cost Optimization", value: 9, hasThreshold: false},
        ];
        return list;
    }
}