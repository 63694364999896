import { makeAutoObservable, runInAction, toJS } from "mobx";
import agent from "../api/agent";
import {LandingDashboardInsightsData } from "../models/insightsModels";

export default class DashboardInsightsStore {
    usageData: LandingDashboardInsightsData;
    loading = false; 
    loadingInitial = false; 

    constructor() {
        makeAutoObservable(this);

        this.usageData = {
            activeEmployees: 0,
            inactiveEmployees: 0,
            activeServices: 0,
            suspendedServices: 0,
            cancelledServices: 0,
            totalNumberOpenEvents: 0,
            totalNumberClosedEvents: 0,
            totalCostOpenEvents: 0,
            totalCostClosedEvents: 0,
            eventsNumberPerMonthCategories: [],
            eventsCostPerMonthCategories: [],
            eventsClosedCostPerMonthSeries: [],
            eventsClosedNumberPerMonthSeries: [],
            eventsOpenCostPerMonthSeries: [],
            eventsOpenNumberPerMonthSeries: [],
            serviceCostPerMonthCategories: [],
            serviceCostPerMonthSeries: []
        }
    }

     setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    loadData = async () => {
        this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            var response = (await agent.DashboardInsights.loadData()); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.usageData = response.data;
            })
            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }
}