import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Tag, TaggedItem } from "../models/tag";
import { SearchParams, SearchParamsTaggedItems } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';

export default class TagStore  {
    tags: Tag[] = [];
    taggedItems: TaggedItem[] = [];
    tagMetaData: Omit<PaginatedResult<Tag>, 'data'> | null = null;
    taggedItemMetaData: Omit<PaginatedResult<TaggedItem>, 'data'> | null = null;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state

    loadingTaggedItems = false; // modal window buttons loading state
    loadingTaggedItemsInitial = false; // list view table loading state

    constructor() {
        makeAutoObservable(this)
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    setloadingTaggedItemsInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingTaggedItemsInitial = state;
        })
    }
    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    // loading state setter 
    setLoadingTaggedItems = (state: boolean) => {
        runInAction(() => {
            this.loadingTaggedItems = state;
        })
    }
    // set pagination meta data
    setTagMetaData = (metaData: Omit<PaginatedResult<Tag>, 'data'>) => {
        runInAction(() => {
            this.tagMetaData = metaData;
        })
    }

    // set pagination meta data
    setTaggedItemMetaData = (metaData: Omit<PaginatedResult<TaggedItem>, 'data'>) => {
        runInAction(() => {
            this.taggedItemMetaData = metaData;
        })
    }

    loadTags = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = ''
    ) => {
        this.setLoadingInitial(true)
        try {
            const params: SearchParams = {
                pageNumber,
                pageSize,
                keyword
            }
            const { data, ...metaData } = await agent.Tags.search(params); 
            runInAction(() => {
                this.tags = data;
            })
            this.setTagMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadTaggedItems = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = '',
        tagId: string= ''
    ) => {
        this.setloadingTaggedItemsInitial(true)
        try {
            const params: SearchParamsTaggedItems = {
                pageNumber,
                pageSize,
                keyword,
                tagId
            }
            const { data, ...metaData } = await agent.Tags.searchItems(params); 
            runInAction(() => {
                this.taggedItems = data;
            })
            this.setTaggedItemMetaData(metaData);
            this.setloadingTaggedItemsInitial(false);
        } catch (error) {
            console.log(error);
            this.setloadingTaggedItemsInitial(false);
        }
    }

    createTag = async (tag: Tag) => {
        this.setLoading(true)
        try {
            const tagRequestBody = {
                name : tag.name,
                tagType : tag.tagType,
            }
            const response = await agent.Tags.create(tagRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    updateTag = async (tag: Tag) => {
        this.setLoading(true)
        try {
            const response = await agent.Tags.update(tag);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    deleteTag = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Tags.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }

    deleteTaggedItem = async (id: string) => {
        this.setLoadingTaggedItems(true)
        try {
            const response = await agent.Tags.deleteTaggedItem(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingTaggedItems(false)
        } catch (error) {
            console.log(error);
            this.setLoadingTaggedItems(false)
            throw error;
        }
    }

    downloadImportTaggedItemFileSample = async(tagId: string): Promise<Blob> => {
        try {
            const response = await agent.Tags.downloadImportTaggedItemFileSample(tagId); 
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    importTaggedItemFromFile = async(importFile: FormData, tagId: string) => {
        try {
            this.setLoading(true);
            const response = await agent.Tags.importTaggedItemFromFile(importFile, tagId); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
            return response;
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }
}