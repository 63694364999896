import {runInAction, makeAutoObservable} from "mobx";
import agent from "../api/agent";
import { CheckoutData, SubscriptionPlan, CurrentSubscription, SubscriptionPaymentDetails } from "app/models/subscription";


export default class SubscriptionStore {
    clientSecret: string;
    currentSubscription: CurrentSubscription;
    subscriptionPlans: SubscriptionPlan[];
    customerId: string;
    subscriptionPaymentDetails: SubscriptionPaymentDetails;
    loading = false;

    constructor() {
        makeAutoObservable(this);
        this.subscriptionPlans = [];
        this.getSubscriptionPlans();
    }

    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    getSubscriptionPlans = async () => {
        try {
            this.setLoading(true);
            const response = await agent.SubscriptionManagement.getSubscriptionPlans();         
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.subscriptionPlans = response.data;
            })
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    createCheckoutSession = async (checkoutData: CheckoutData) => {
        try {
            const response = await agent.SubscriptionManagement.createCheckoutSession(checkoutData);         
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.clientSecret = response.data;
            })
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    getCurrentSubscription = async () => {
        try {
            const response = await agent.SubscriptionManagement.getCurrentSubscription();         
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.currentSubscription = response.data;
            })
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    cancelSubscription = async () => {
        try {
            const response = await agent.SubscriptionManagement.cancelSubscription();         
            if (!response.succeeded) throw new Error(response.messages[0]);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    getPaymentDetails = async () => {
        try {
            const response = await agent.SubscriptionManagement.getSubscriptionPaymentDetails();         
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.subscriptionPaymentDetails = response.data;
            })
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}