import { makeAutoObservable, runInAction, toJS } from "mobx";
import agent from "../api/agent";
import {ComplianceInsightsFilterOptions, DateRangeFilterOptionEnum, ComplianceInsightsData, ComplianceInsightsFilter } from "../models/insightsModels";

export default class ComplianceInsightsStore {
    complianceFilterOptions: ComplianceInsightsFilterOptions;
    complianceData: ComplianceInsightsData;
    loading = false; 
    loadingInitial = false; 

    constructor() {
        makeAutoObservable(this);

        this.complianceFilterOptions = {
            providers: [],
            serviceTypes: [],
            departments: [],
            tags: [],
            dateRangeFilterOptions: [],
            policyTypes: [],
            assignedToList : [],
            fromDate: null,
            toDate: null
          };
      
          this.complianceData = {
            totalEvents: 0,
            totalOpenEvents: 0,
            totalClosedEvents: 0,
            totalEventsCost: 0,
            totalOpenEventsCost: 0,
            totalClosedEventsCost: 0,
            eventsOpenNumberPerMonthCategories: [],
            eventsOpenNumberPerMonthSeries: [],
            eventsClosedNumberPerMonthCategories: [],
            eventsClosedNumberPerMonthSeries: [],
            eventsOpenCostPerMonthCategories: [],
            eventsOpenCostPerMonthSeries: [],
            eventsClosedCostPerMonthCategories: [],
            eventsClosedCostPerMonthSeries: []

          };
    }

     setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    loadComplianceData = async (filter: ComplianceInsightsFilter) => {
        this.setLoadingInitial(true)
        try {
            var response = (await agent.ComplianceInsights.loadData(filter)); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.complianceData = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getComplianceFilterOptions = async () => {
        this.setLoadingInitial(true)
        try {
            this.complianceFilterOptions =  await agent.ComplianceInsights.getFilterOptions(); 
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
}