import {
  Box,
  Divider,
  List,
  ListItemButton,
  styled,
  Tooltip,
} from "@mui/material";
import ScrollBar from "simplebar-react";
import navList from "./navList";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Roles } from "app/models/roles";
import { Fragment } from "react";
import { H5, H6, H4 } from "components/Typography";

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  width: 190,
  height: "100%",
  position: "fixed",
  left: 0,
  boxShadow: theme.shadows[2],
  zIndex: theme.zIndex.drawer + 11,
  transition: "left 0.3s ease",
  backgroundColor: theme.palette.background.paper,
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "0.5rem",
  justifyContent: "left",
  "&:hover": { backgroundColor: "#2499EF",color:"white" },
  fontSize: "13px"
}));

// root component
const SideBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const { currentUserStore } = useStore();

  return (
    <MainMenu>
      <List sx={{ height: "100%" }}>
        <StyledListItemButton disableRipple sx={{  justifyContent: "center", "&:hover": { backgroundColor: "transparent" },}}>
          <img src="/logo/nanoLogo.png" alt="Telco Manage" width={31} />
        </StyledListItemButton>
        <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
          {navList.filter(item => !item.roles || item.roles.includes(currentUserStore.currentUser?.roleId as Roles)).map((nav, index) => (
            <Fragment key={index}>
              {nav.topDivider && <Divider variant="middle" sx={{ marginBottom: "15px" }} />}
              {nav.category && <H4 sx={{ marginBottom: "15px", paddingLeft:"10px" }}>{nav.category}</H4>}
              {/* <Tooltip title={nav.title} placement="right" > */}
                <StyledListItemButton
                  disableRipple
                  onClick={() => navigate(nav.path)}
                >
                  <nav.Icon
                    sx={{
                      color:
                        nav.path === pathname ? "primary.main" : "secondary.400",
                    }}
                  />

                <H6   sx={{paddingLeft: "3px",
                      color:
                        nav.path === pathname ? "primary.main" : "secondary.400",
                    }}>{nav.title}</H6>
                </StyledListItemButton>
              {/* </Tooltip> */}
            </Fragment>
          ))}
        </ScrollBar>
      </List>
    </MainMenu>
  );
};


export default observer(SideBar);
