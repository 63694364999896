import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { WirelessUsage } from "../models/wirelessUsage";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';

export default class WirelessUsageStore {
    wirelessUsageData: WirelessUsage[] = [];
    usageMetaData: Omit<PaginatedResult<WirelessUsage>, 'data'> | null = null;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state

    constructor() {
        makeAutoObservable(this)
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    // set pagination meta data
    setWirelessUsageMetaData = (metaData: Omit<PaginatedResult<WirelessUsage>, 'data'>) => {
        runInAction(() => {
            this.usageMetaData = metaData;
        })
    }

    loadUsage = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = ''
    ) => {
        this.setLoadingInitial(true)
        try {
            const params: SearchParams = {
                pageNumber,
                pageSize,
                keyword
            }
            const { data, ...metaData } = await agent.WirelessUsages.search(params); 
            runInAction(() => {
                this.wirelessUsageData = data;
            })
            this.setWirelessUsageMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    deleteUsage = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.WirelessUsages.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
    
    downloadImportFileSample = async(): Promise<Blob> => {
        try {
            const response = await agent.WirelessUsages.downloadImportFileSample(); 
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    uploadImportUsageFile = async(importFile: FormData) => {
        try {
            this.setLoading(true);
            const response = await agent.WirelessUsages.importUsageFromFile(importFile); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
            return response;
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }
}