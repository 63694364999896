import { makeAutoObservable, runInAction, toJS } from "mobx";
import agent from "../api/agent";
import {UsageInsightsFilter, UsageInsightsFilterOptions, DateRangeFilterOptionEnum, CostInsightsData } from "../models/insightsModels";

export default class CostInsightsStore {
    costFilterOptions: UsageInsightsFilterOptions;
    costData: CostInsightsData;
    loading = false; 
    loadingInitial = false; 

    constructor() {
        makeAutoObservable(this);

        this.costFilterOptions = {
            providers: [],
            serviceTypes: [],
            tags: [],
            departments: [],
            dateRangeFilterOptions: []
        };
        
        this.costData = {
            totalCost: 0,
            servicesInContract: 0,
            servicesMonthToMonth: 0,
            earlyTerminationFeeTotal: 0,
            costCategories: [],
            costSeries: []
        }
       
    }

     setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    loadCostData = async (filter: UsageInsightsFilter) => {
        this.setLoadingInitial(true)
        try {
            var response = (await agent.CostInsights.loadData(filter)); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.costData = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getCostFilterOptions = async () => {
        this.setLoadingInitial(true)
        try {
            this.costFilterOptions =  await agent.CostInsights.getFilterOptions(); 
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
}