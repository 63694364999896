import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { WirelessService } from "../models/wirelessService";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';

export default class WirelessServiceStore {
    wirelessServices: WirelessService[] = [];
    serviceMetaData: Omit<PaginatedResult<WirelessService>, 'data'> | null = null;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state

    constructor() {
        makeAutoObservable(this)
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    // set pagination meta data
    setWirelessServiceMetaData = (metaData: Omit<PaginatedResult<WirelessService>, 'data'>) => {
        runInAction(() => {
            this.serviceMetaData = metaData;
        })
    }

    loadServices = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = ''
    ) => {
        this.setLoadingInitial(true)
        try {
            const params: SearchParams = {
                pageNumber,
                pageSize,
                keyword
            }
            const { data, ...metaData } = await agent.WirelessServices.search(params); 
            runInAction(() => {
                this.wirelessServices = data;
            })
            this.setWirelessServiceMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    createService = async (service: WirelessService) => {
        this.setLoading(true)
        try {
            const serviceRequestBody = {
                number: service.number,
                provider: service.provider,
                simNumber: service.simNumber,
                imei: service.imei,
                assignedTo: service.assignedTo,
                assignedToId: service.assignedToId,
                planName: service.planName,
                monthlyCost : service.monthlyCost,
                contractStart: service.contractStart,
                contractEnd: service.contractEnd,
                smsQuota: service.smsQuota,
                voiceQuotaMinutes: service.voiceQuotaMinutes,
                dataQuotaMegabytes: service.dataQuotaMegabytes,
                accountNumber: service.accountNumber,
                status: service.status,
                earlyTerminationFee: service.earlyTerminationFee
            };
            const response = await agent.WirelessServices.create(serviceRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    updateService = async (service: WirelessService) => {
        this.setLoading(true)
        try {
            const response = await agent.WirelessServices.update(service);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    deleteService = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.WirelessServices.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
    
    downloadImportFileSample = async(): Promise<Blob> => {
        try {
            const response = await agent.WirelessServices.downloadImportFileSample(); 
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    uploadImportWirelessFile = async(importFile: FormData) => {
        try {
            this.setLoading(true);
            const response = await agent.WirelessServices.importServicesFromFile(importFile); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
            return response;
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    carrierList = (): string[] => {
        let list: string[] = 
        [
            "T-Mobile",
            "Verizon",
            "AT&T",
            "Orange",
            "Vodafone",
            "O2",
            "BT",
            "Tesco",
            "Rangers",
            "Bell",
            "Telus"
        ];
        return list;
    }
}