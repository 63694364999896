import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "material-react-toastify";
import agent from "../api/agent";
import { CreateTenantRequest, Tenant } from "../models/tenant";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';


export default class TenantStore {
    tenants: Tenant[] = [];
    tenantMetaData: Omit<PaginatedResult<Tenant>, 'data'> | null = null;

    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    // loading setter (initial page load)
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading setter
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

      // set pagination meta data
      setTenantMetaData = (metaData: Omit<PaginatedResult<Tenant>, 'data'>) => {
        runInAction(() => {
            this.tenantMetaData = metaData;
        })
    }

    // load tenants
    loadTenants = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = ''
    ) => {
        this.setLoadingInitial(true);
        const params: SearchParams = {
            pageNumber,
            pageSize,
            keyword
        }
        try {
            const { data, ...metaData } = await agent.Tenants.searchPaginated(params); 
            runInAction(() => {
                this.tenants = data;
            })
            this.setTenantMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            throw error;
        }
    }

    // create new tenant
    createTenant = async (createTenantRequest: CreateTenantRequest) => {
        this.setLoading(true);

        try {
            const response = await agent.Tenants.create(createTenantRequest);
            if (!response.succeeded) throw new Error(response.messages[0]);
            const newTenant = response.data;
            runInAction(() => {
                this.tenants.push(newTenant); // add to registry list (local memory) - prevents having to reload the table
            })
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update tenant
    updateTenant = async (tenant: Tenant) => {
        this.setLoading(true);
        try {
            const response = await agent.Tenants.update(tenant);
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                const tenantIndex = this.tenants.findIndex(x => x.id == tenant.id);
                this.tenants[tenantIndex] = tenant;
            })
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }
}