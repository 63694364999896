import { makeAutoObservable, runInAction, toJS } from "mobx";
import agent from "../api/agent";
import {UsageInsightsFilter, UsageInsightsFilterOptions,UsageInsightsData, DateRangeFilterOptionEnum } from "../models/insightsModels";

export default class UsageInsightsStore {
    usageFilterOptions: UsageInsightsFilterOptions;
    usageData: UsageInsightsData;
    loading = false; 
    loadingInitial = false; 

    constructor() {
        makeAutoObservable(this);
        
        this.usageFilterOptions = {
            providers: [],
            serviceTypes: [],
            tags: [],
            departments: [],
            dateRangeFilterOptions: []
        };
        
        this.usageData = {
            activeServicesCount: 0,
            cancelledServicesCount: 0,
            suspendedServicesCount: 0,
            voiceUsageCategories: [],
            voiceUsageSeries: [],
            smsUsageCategories: [],
            smsUsageSeries: [],
            dataUsageCategories: [],
            dataUsageSeries: []
        }
    }

     setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    loadData = async (filter: UsageInsightsFilter) => {
        this.setLoadingInitial(true)
        try {
            var response = (await agent.UsageInsights.loadData(filter)); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.usageData = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    getFilterOptions = async () => {
        this.setLoadingInitial(true)
        try {
            this.usageFilterOptions =  await agent.UsageInsights.getFilterOptions(); 
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
}