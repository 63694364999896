import { FC, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Modal,

} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { toast } from "material-react-toastify";
import {DarkTextField} from "components/formInput/InputsDark";
import FlexBox from "components/FlexBox";
import { H2, H6 } from "components/Typography";
import { useStore } from "app/stores/store";
import StyledModalCard from "components/StyledModalCard";
import {SupportRequest} from 'app/models/currentUser'


interface Props {
  open: boolean;
  onClose: () => void;
}

const SupportModal: FC<Props> = ({ open, onClose }) => {
  const [formValues] = useState<SupportRequest>({
    subject: '',
    request: '',
  });

  const { currentUserStore } = useStore();
  const { requestSupport } = currentUserStore;

  const validationSchema = Yup.object({
    subject: Yup.string().required('Subject is required'),
    request: Yup.string().required('Please enter details for the request')
    
  });

  const { values, errors, handleChange, handleSubmit, touched, handleBlur, dirty, isSubmitting, isValid, resetForm } = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: async (request: SupportRequest) => {
        try {
          await requestSupport(request);
          handleClose()
          toast.dark("A support request was created. A team member will contact you within 24 hrs.")
        } catch (error) {
          const message = (error as Error)?.message || "Creating a support request failed";
          toast.error(message);
        }
    }
  });

  const handleClose = () => {
    resetForm();
    onClose();
  }

  return (
    
    <Modal open={open} onClose={handleClose}>
      <StyledModalCard>
        <H2 mb={2}>Create a Support Request</H2>
        <Divider />
        <form onSubmit={handleSubmit}>
          <Grid mt={1} container spacing={3} columnSpacing={5} className="main-form">
            <Grid item xs={12} sm={12}>
              <H6 mb={1}>Subject</H6>
              <DarkTextField
                id="subject"
                name="subject"
                placeholder="Enter Subject"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.subject}
                error={Boolean(errors.subject && touched.subject)}
                helperText={touched.subject && errors.subject}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <H6 mb={1}>Request</H6>
              <DarkTextField
                id="request"
                rows={4}
                multiline
                name="request"
                placeholder="Enter Request"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.request}
                error={Boolean(errors.request && touched.request)}
                helperText={touched.request && errors.request}
              />
            </Grid>
          
        
          </Grid>

          <FlexBox justifyContent="flex-end" marginTop={4}>
            <Button
              fullWidth
              size="small"
              variant="outlined"
              onClick={() => handleClose()}
              sx={{
                width: 124,
                fontSize: 12,
                marginRight: 2,
                color: "text.disabled",
                borderColor: "text.disabled",
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              size="small"
              type="submit"
              variant="contained"
              disabled={!dirty || !isValid || isSubmitting}
              sx={{ width: 124, fontSize: 12 }}
            >
              Send
            </LoadingButton>
          </FlexBox>
        </form>
      </StyledModalCard>
    </Modal>
  );
};

export default observer(SupportModal);
