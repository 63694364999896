import { Roles } from 'app/models/roles';
import React, { Suspense, Fragment, lazy, LazyExoticComponent, FC } from 'react';
import {  Route } from 'react-router-dom';
import AuthGuard from './navigation/authGuards/AuthGuard';
import GuestGuard from './navigation/authGuards/GuestGuard';
import AppLayout from './navigation/AppLayout';
import LoadingScreen from './components/LoadingScreen';
import {Helmet} from "react-helmet";

interface RouteType {
  guard?: (props: any) => JSX.Element;
  layout?: (props: any) => JSX.Element;
  component?: (props: any) => JSX.Element;
  routes?: RouteType[];
  path?: string;
  roles?: Roles[];
  title?: string;
}

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const NotFoundPage = Loadable(lazy(() => import('./pages/404')));
const UnauthorizedPage = Loadable(lazy(() => import('./pages/403'))); 
const ErrorPage = Loadable(lazy(() => import('./pages/500'))); 
const LoginPage = Loadable(lazy(() => import('./pages/authentication/Login')));
const ForgotPasswordPage = Loadable(lazy(() => import('./pages/authentication/ForgotPassword')));
const ResetPasswordPage = Loadable(lazy(() => import('./pages/authentication/ResetPassword')));
const SignUpPage = Loadable(lazy(() => import('./pages/authentication/SignUp')));
const ConfirmAccountPage = Loadable(lazy(() => import('./pages/authentication/ConfirmAccount')));
const TenantListPage = Loadable(lazy(() => import('./pages/tenants/TenantList')));
const UserListPage = Loadable(lazy(() => import('./pages/users/UserList')));
const AccountSettingsPage = Loadable(lazy(() => import('./pages/profile/AccountSettings')));
const EmployeeListPage = Loadable(lazy(() => import('./pages/employees/EmployeeList')));
const DashboardPage = Loadable(lazy(() => import('./pages/usageInsights/LandingDashboard')));
const CompanyPage = Loadable(lazy(() => import('./pages/company/CompanyInfo')));
const WirelessServiceListPage = Loadable(lazy(() => import('./pages/wirelessServices/WirelessServiceList')));
const WirelessUsageListPage = Loadable(lazy(() => import('./pages/wirelessUsage/WirelessUsageList')));
const PolicyListPage = Loadable(lazy(() => import('./pages/policies/PolicyList')));
const ComplianceEventListPage = Loadable(lazy(() => import('./pages/complianceEvents/ComplianceEventList')));
const TagListPage = Loadable(lazy(() => import('./pages/tags/TagList')));
const HangfireDashboardPage = Loadable(lazy(() => import('./pages/common/HangfireDashboard')));
const UsageInsightsPage = Loadable(lazy(() => import('./pages/usageInsights/UsageDashboard')));
const CostInsightsPage = Loadable(lazy(() => import('./pages/usageInsights/CostDashboard')));
const ComplianceInsightsPage = Loadable(lazy(() => import('./pages/usageInsights/ComplianceDashboard')));
const BillingSettingsPage = Loadable(lazy(() => import('./pages/subscription/BillingSettings')));

export const renderRoutes = (routes: RouteType[] = []) => (
  routes.map((route, i) => {
    const Guard = route.guard || React.Component;
    const Layout = route.layout || Fragment;
    const Component = route.component || React.Component;

    return (
      <Route
        key={i}
        path={route.path}
        element={(
          <Fragment>
            {route.title &&
              <Helmet>
                <title>{route.title}</title>
              </Helmet>
            }
            {route.guard ?
              <Guard roles={route.roles}>
                <Layout>
                  <Component />
                </Layout>
              </Guard> :
              <Layout>
                <Component />
              </Layout>
            }
          </Fragment>
        )}
      />
    ) })
);

const appName = "Telco Manage"

const routes: RouteType[] = [
  {
    path: '/',
    guard: GuestGuard,
    component: LoginPage,
    title: `${appName} | Login`
  },
  {
    path: '/404',
    component: NotFoundPage,
    title: `${appName} | Not Found`
  },
  {
    path: '/500',
    component: ErrorPage,
    title: `${appName} | Application Error`
  },
  {
    path: '/403',
    component: UnauthorizedPage,
    title: `${appName} | Unauthorized`
  },
  {
    path: '/login',
    guard: GuestGuard,
    component: LoginPage,
    title: `${appName} | Login`
  },
  {
    path: '/forgot-password',
    guard: GuestGuard,
    component: ForgotPasswordPage,
    title: `${appName} | Forgot Password`
  },
  {
    path: '/reset-password',
    guard: GuestGuard,
    component: ResetPasswordPage,
    title: `${appName} | Reset Password`
  },
  {
    path: '/sign-up',
    guard: GuestGuard,
    component: SignUpPage,
    title: `${appName} | Sign Up`
  },
  {
    path: '/confirm-account',
    guard: GuestGuard,
    component: ConfirmAccountPage,
    title: `${appName} | Confirm Account`
  },
  {
    path: '/profile',
    guard: AuthGuard,
    component: AccountSettingsPage,
    title: `${appName} | Profile`,
    layout: AppLayout,
  },
  {
    path: '/users',
    component: UserListPage,
    guard: AuthGuard,
    roles: [Roles.root, Roles.client_admin],
    title: `${appName} | Users`,
    layout: AppLayout,
  },
  {
    path: '/tenants',
    component: TenantListPage,
    guard: AuthGuard,
    roles: [Roles.root],
    title: `${appName} | Tenants`,
    layout: AppLayout,
  },
  {
    path: '/employees',
    component: EmployeeListPage,
    guard: AuthGuard,
    roles: [Roles.client_admin],
    title: `${appName} | Employees`,
    layout: AppLayout,
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    guard: AuthGuard,
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops],
    title: `${appName} | Dashboard`,
    layout: AppLayout,
  },
  {
    path: '/company',
    component: CompanyPage,
    guard: AuthGuard,
    roles: [Roles.client_admin],
    title: `${appName} | Company`,
    layout: AppLayout,
  },
  {
    path: '/wireless-services',
    component: WirelessServiceListPage,
    guard: AuthGuard,
    roles: [Roles.client_admin],
    title: `${appName} | Wireless Services`,
    layout: AppLayout,
  },
  {
    path: '/wireless-usage',
    component: WirelessUsageListPage,
    guard: AuthGuard,
    roles: [Roles.client_admin, Roles.client_ops],
    title: `${appName} | Wireless Usage`,
    layout: AppLayout,
  },
  {
    path: '/policy',
    component: PolicyListPage,
    guard: AuthGuard,
    roles: [Roles.client_admin],
    title: `${appName} | Policies`,
    layout: AppLayout,
  },
  {
    path: '/compliance-events',
    component: ComplianceEventListPage,
    guard: AuthGuard,
    roles: [Roles.client_admin, Roles.client_ops],
    title: `${appName} | Compliance Events`,
    layout: AppLayout,
  },
  {
    path: '/tags',
    component: TagListPage,
    guard: AuthGuard,
    roles: [Roles.client_admin, Roles.client_finance],
    title: `${appName} | Tags`,
    layout: AppLayout,
  },
  {
    path: '/hangfire',
    component: HangfireDashboardPage,
    guard: AuthGuard,
    roles: [Roles.root],
    title: `${appName} | Hangfire Dashboard`,
    layout: AppLayout,
  },
  {
    path: '/usage-insights',
    component: UsageInsightsPage,
    guard: AuthGuard,
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops],
    title: `${appName} | Usage Insights`,
    layout: AppLayout,
  },
  {
    path: '/cost-insights',
    component: CostInsightsPage,
    guard: AuthGuard,
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops],
    title: `${appName} | Cost Insights`,
    layout: AppLayout,
  },
  {
    path: '/compliance-insights',
    component: ComplianceInsightsPage,
    guard: AuthGuard,
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops],
    title: `${appName} | Compliance Insights`,
    layout: AppLayout,
  },
  {
    path: '/billing',
    component: BillingSettingsPage,
    guard: AuthGuard,
    roles: [Roles.client_admin],
    title: `${appName} | Billing`,
    layout: AppLayout,
  },



  {
    path: '*',
    component: NotFoundPage
  }
];

export default routes;