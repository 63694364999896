import { createContext, useContext } from "react";
import AppUserStore from "./appUserStore";
import CommonStore from "./commonStore";
import TenantStore from "./tenantStore";
import CurrentUserStore from "./currentUserStore";
import EmployeeStore from "./employeeStore";
import CompanyStore from "./companyStore";
import WirelessServiceStore from "./wirelessServiceStore";
import WirelessUsageStore from "./wirelessUsageStore"
import PolicyStore from "./policyStore";
import ComplianceEventStore from "./complianceEventStore";
import TagStore from './tagStore'
import UsageInsightsStore from "./usageInsightsStore";
import CostInsightsStore from "./costInsightsStore";
import ComplianceInsightsStore from "./complianceInsightsStore";
import DashboardInsightsStore from "./dashboardInsightsStore";
import SubscriptionStore from "./subscriptionStore";

// base Mobx store
interface Store {
    commonStore: CommonStore;
    currentUserStore: CurrentUserStore;
    appUserStore: AppUserStore;
    tenantStore: TenantStore;
    employeeStore: EmployeeStore;
    companyStore: CompanyStore;
    wirelessServiceStore: WirelessServiceStore;
    wirelessUsageStore: WirelessUsageStore;
    policyStore: PolicyStore;  
    complianceEventStore: ComplianceEventStore;
    tagStore: TagStore;
    usageInsightsStore: UsageInsightsStore;
    costInsightStore: CostInsightsStore;
    complianceInsightsStore: ComplianceInsightsStore;
    dashboardInsightsStore: DashboardInsightsStore;
    subscriptionStore: SubscriptionStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    currentUserStore: new CurrentUserStore(),
    appUserStore: new AppUserStore(),
    tenantStore: new TenantStore(),
    employeeStore: new EmployeeStore(),
    companyStore: new CompanyStore(),
    wirelessServiceStore: new WirelessServiceStore(),
    wirelessUsageStore: new WirelessUsageStore(),
    policyStore: new PolicyStore(),
    complianceEventStore: new ComplianceEventStore(),
    tagStore: new TagStore(),
    usageInsightsStore: new UsageInsightsStore(),
    costInsightStore: new CostInsightsStore(),
    complianceInsightsStore: new ComplianceInsightsStore(),
    dashboardInsightsStore: new DashboardInsightsStore(),
    subscriptionStore: new SubscriptionStore()
}

export const StoreContext = createContext(store); 

export function useStore() { 
    return useContext(StoreContext);
}