import AccountSettingsIcon from "./AccountSettings";
import PagesIcon from "./Pages";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";
import DashboardIcon from "@mui/icons-material/DashboardRounded"
import BadgeIcon from "@mui/icons-material/BadgeRounded"
import MobileIcon from "@mui/icons-material/PhoneIphoneRounded"
import BusinessIcon from "@mui/icons-material/BusinessRounded"
import PolicyIcon from "@mui/icons-material/PolicyRounded"
import NotificationsIcon from "@mui/icons-material/NotificationsActiveRounded"
import BillIcon from "@mui/icons-material/PaymentRounded"
import TagIcon from "@mui/icons-material/LocalOfferRounded"
import UsageIcon from "@mui/icons-material/DataUsageRounded"
import CostIcon from "@mui/icons-material/AttachMoneyRounded"
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkloadRounded"
import InsightsIcon from '@mui/icons-material/Insights';

export default {
  PagesIcon,
  UserProfileIcon,
  UserManagementIcon,
  AccountSettingsIcon,
  DashboardIcon,
  BadgeIcon,
  MobileIcon,
  BusinessIcon,
  PolicyIcon,
  NotificationsIcon,
  BillIcon,
  TagIcon,
  UsageIcon,
  CostIcon,
  AssuredWorkloadIcon,
  InsightsIcon
};

