import { Roles } from "app/models/roles";
import Icons from "../../icons/sidebar";

// list of navigation items that appear in sidebar
const navList = [
  // dashboard
  {
    title: "Dashboard",
    Icon: Icons.DashboardIcon,
    path: "/dashboard",
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops], 
    topDivider: false,
    category: null,
  },
  {
    title: "SaaS Dashboard",
    Icon: Icons.DashboardIcon,
    path: "/saas-dashboard",
    roles: [Roles.root],
    topDivider: false,
    category: null,
  },
  // Configuration
  {
    title: "Users",
    Icon: Icons.UserProfileIcon,
    path: "/users",
    roles: [Roles.root, Roles.client_admin], 
    topDivider: true,
    category: "Configuration"
  },
  {
    title: "Tenants",
    Icon: Icons.AccountSettingsIcon,
    path: "/tenants",
    roles: [Roles.root],
    topDivider: false,
    category: null,
  },
  {
    title: "Employees",
    Icon: Icons.BadgeIcon,
    path: "/employees",
    roles: [Roles.client_admin], 
    topDivider: false,
    category: null,
  },
  {
    title: "Wireless Services",
    Icon: Icons.MobileIcon,
    path: "/wireless-services",
    roles: [Roles.client_admin], 
    topDivider: false,
    category: null,
  },
  {
    title: "Tags",
    Icon: Icons.TagIcon,
    path: "/tags",
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops], 
    topDivider: false,
    category: null,
  },
  {
    title: "Company",
    Icon: Icons.BusinessIcon,
    path: "/company",
    roles: [Roles.client_admin], 
    topDivider: false,
    category: null,
  },
  // Operations
  {
    title: "Usage",
    Icon: Icons.UsageIcon,
    path: "/wireless-usage",
    roles: [Roles.client_admin, Roles.client_ops], 
    topDivider: true,
    category: "Operations",
  },
  {
    title: "Policies",
    Icon: Icons.PolicyIcon,
    path: "/policy",
    roles: [Roles.client_admin], 
    topDivider: false,
    category: null,
  },
  {
    title: "Events",
    Icon: Icons.NotificationsIcon,
    path: "/compliance-events",
    roles: [Roles.client_admin, Roles.client_ops], 
    topDivider: false,
    category: null,
  },
  {
    title: "Hangfire",
    Icon: Icons.NotificationsIcon,
    path: "/hangfire",
    roles: [Roles.root,], 
    topDivider: true,
    category: "Operations",
  },
   //Insights
  {
    title: "Usage",
    Icon: Icons.InsightsIcon,
    path: "/usage-insights",
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops], 
    topDivider: true,
    category: "Insights",
  },
  {
    title: "Cost",
    Icon: Icons.CostIcon,
    path: "/cost-insights",
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops], 
    topDivider: false,
    category: null,
  },
  {
    title: "Compliance",
    Icon: Icons.AssuredWorkloadIcon,
    path: "/compliance-insights",
    roles: [Roles.client_admin, Roles.client_finance, Roles.client_ops], 
    topDivider: false,
    category: null,
  }
];

export default navList;
