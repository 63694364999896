import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ComplianceEvent, UpdateComplianceEvent, UserEventAssignmentOption } from "../models/complianceEvent";
import { ComplianceEventSearchParams } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';

export default class ComplianceEventStore  {
    complianceEvents: ComplianceEvent[] = [];
    userEventAssignmentList: UserEventAssignmentOption[] = [];
    complianceEventMetaData: Omit<PaginatedResult<ComplianceEvent>, 'data'> | null = null;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state

    constructor() {
        makeAutoObservable(this)
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    // set pagination meta data
    setMetaData = (metaData: Omit<PaginatedResult<ComplianceEvent>, 'data'>) => {
        runInAction(() => {
            this.complianceEventMetaData = metaData;
        })
    }

    loadComplianceEvents = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = '',
        eventStatus: number | null,
        assignedTo: string | null
    ) => {
        this.setLoadingInitial(true)
        try {
            const params: ComplianceEventSearchParams = {
                pageNumber,
                pageSize,
                keyword,
                eventStatus,
                assignedTo
            }
            const { data, ...metaData } = await agent.ComplianceEvents.search(params); 
            if (this.userEventAssignmentList.length == 0) 
                this.userEventAssignmentList = await agent.ComplianceEvents.getUsersForAssignList();
            runInAction(() => {
                this.complianceEvents = data;
            })
            this.setMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    updateComplianceEvent = async (record: UpdateComplianceEvent) => {
        this.setLoading(true)
        try {
            const response = await agent.ComplianceEvents.update(record);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    eventStatusList = (): any[] => {
        let list: any[] = 
        [
            {description: "New", value: 0},
            {description: "Assigned", value: 1},
            {description: "In Progress", value: 2},
            {description: "Awaiting Third Party", value: 3},
            {description: "Blocked", value: 4},
            {description: "Closed", value: 5},
        ];
        return list;
    }
}