import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'material-react-toastify';
import { store } from '../stores/store';
import { TokenData, UserLogin, ForgotPasswordRequest, ResetPasswordRequest, UserSignUpRequest, ConfirmAccountRequest} from '../models/auth';
import { User, RegisterUserRequest} from '../models/user';
import { ChangePasswordRequest, UpdateProfileRequest, UpdatePreferencesRequest, ChangeProfileImageRequest, CurrentUser, SupportRequest } from '../models/currentUser';
import { SearchParams, ComplianceEventSearchParams, SearchParamsTaggedItems } from '../models/searchParams';
import { PaginatedResult, Result } from '../models/responseWrappers';
import { CreateTenantRequest, Tenant } from '../models/tenant';
import { AddTagRequest, Tag, TaggedItem } from '../models/tag';
import { AddPolicyRequest, Policy } from '../models/policy';
import { AddEmployeeRequest, Employee } from 'app/models/employee';
import { AddWirelessServiceRequest, WirelessService } from 'app/models/wirelessService';
import { AddWirelessUsageRequest, WirelessUsage } from 'app/models/wirelessUsage';
import { Company } from 'app/models/company';
import { ComplianceEvent, UpdateComplianceEvent, UserEventAssignmentOption } from '../models/complianceEvent';
import {UsageInsightsFilter, UsageInsightsFilterOptions, UsageInsightsData, CostInsightsData,
    ComplianceInsightsFilterOptions, ComplianceInsightsData, ComplianceInsightsFilter, LandingDashboardInsightsData} from '../models/insightsModels';
import { CheckoutData, SubscriptionPlan, CurrentSubscription, SubscriptionPaymentDetails } from 'app/models/subscription';

// Base URL
// -- development: https://localhost:7250/api
// -- production: (your domain)
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Send up the token with every request, when there is a token
axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    config.headers = {
        Tenant: store.commonStore.tenant ?? '',
    };
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config;
})

// Axios reponse interceptors
axios.interceptors.response.use(async response => {
    //if (process.env.NODE_ENV === 'development') await sleep(1000); // Artifical delay for development
    return response;
}, (error: AxiosError) => { // Basic error handling for 400 and 500 type errors
    const { data, status } = error.response!;
    switch (status) {
        case 400:
            toast.error('Error code 400: bad request');
            break;
        case 401:
            toast.error('Error code 401: unauthorized');
            store.currentUserStore.logout();
            break;
        case 500:
            toast.error('Error code 500: internal server error');
            console.log(data);
            break;
    }
    return Promise.reject(error);
})
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

// Axios Base
const requests = {
    get: <T>(url: string, options? : {}) => axios.get<T>(url,options).then(responseBody),
    post: <T>(url: string, body: {}, options?: {}) => axios.post<T>(url, body, options).then(responseBody),
    put: <T>(url: string, body: {}, options?: {}) => axios.put<T>(url, body, options).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

// Authentication & Profile Management (Current User)
const Account = {
    current: () => requests.get<Result<CurrentUser>>('/identity/profile'),
    login: (user: UserLogin) => requests.post<Result<TokenData>>(`/tokens`, user),
    update: (user: UpdateProfileRequest) => requests.put<Result<CurrentUser>>(`/identity/profile`, user),
    updateProfile: (user: UpdateProfileRequest) => requests.put<Result>(`/identity/profile`, user),
    changeProfileImage: (changeProfileImageRequest: ChangeProfileImageRequest)=> {
        let formData = new FormData(); // form data to send up a file
        Object.entries(changeProfileImageRequest).forEach( ([key, val]) => {
            formData.append(key, val);
        })
        return requests.put<Result<string>>('/identity/profile-image', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        })
    },
    updatePreferences: (updatePreferencesRequest: UpdatePreferencesRequest) => requests.put<Result>(`/identity/preferences`, updatePreferencesRequest),
    changePassword: (changePasswordRequest: ChangePasswordRequest) => requests.put<Result>(`/identity/change-password`, changePasswordRequest),
    forgotPassword: (forgotPasswordRequest: ForgotPasswordRequest) => requests.post<Result>(`/identity/forgot-password`, forgotPasswordRequest),
    resetPassword: (resetPasswordRequest: ResetPasswordRequest) => requests.post<Result>(`/identity/reset-password`, resetPasswordRequest),
    signUp: (signUpRequest: UserSignUpRequest) => requests.post<Result>(`/identity/sign-up`, signUpRequest),
    confirmAccount: (confirmAccountRequest: ConfirmAccountRequest) => requests.post<Result>(`/identity/confirm-account`, confirmAccountRequest),
    requestSupport: (request: SupportRequest) => requests.post<Result>(`/subscription/request-support`, request)
}

const Employees = {
    searchPaginated: (params: SearchParams) => requests.post<PaginatedResult<Employee>>(`/employees/EmployeeListPaginated`, params), // paginated list handled server-side
    search: (params: SearchParams) => requests.post<Result<Employee[]>>(`/employees/EmployeesListFiltered`,params), 
    create: (employee: AddEmployeeRequest) => requests.post<Result<Employee>>('/employees/create', employee),
    details: (id: string) => requests.get<Result<Employee>>(`/employees/${id}`),
    update: (employee: Employee) => requests.put<Result<Employee>>(`/employees/update/${employee.id}`, employee),
    delete: (id: string) => requests.del<Result<string>>(`/employees/${id}`),
    downloadImportFileSample: () => requests.get<Blob>(`/employees/download-employee-import-file-sample`,{responseType: 'blob'}),
    importServicesFromFile: (importFile: FormData) => requests.post<Result<string>>(`/employees/import-employees-from-file`,importFile),
}

// User Management
const Users = {
    searchPaginated: (params: SearchParams) => requests.post<PaginatedResult<User>>(`/identity/UserListPaginated`, params),
    create: (appUser: RegisterUserRequest) => requests.post<Result<User>>(`/identity/register`, appUser),
    details: (id: string) => requests.get<Result<User>>(`/identity/user/${id}`),
    update: (user: User) => requests.put<Result<User>>(`/identity/user/${user.id}`, user),
    delete: (id: string) => requests.del<Result<string>>(`/identity/user/${id}`),
}

// Tenant Management
const Tenants = {
    searchPaginated: (params: SearchParams) => requests.post<PaginatedResult<Tenant>>(`/tenants/TenantListPaginated`, params),
    details: (id: string) => requests.get<Result<Tenant>>(`/tenants/${id}`),
    create: (tenant: CreateTenantRequest) => requests.post<Result<Tenant>>(`/tenants`, tenant),
    update: (tenant: Tenant) => requests.put<Result<Tenant>>(`/tenants/`, tenant), 
}

const CompanyInfo = {
    load: () => requests.get<Result<Company>>(`/company/company-info  `),
    update: (company: Company) => requests.post<Result<Company>>(`/company/update-company-info`, company)
}

const WirelessServices = {
    search: (params: SearchParams) => requests.post<PaginatedResult<WirelessService>>(`/WirelessServices/WirelessListPaginated`, params), 
    create: (service: AddWirelessServiceRequest) => requests.post<Result<WirelessService>>('/WirelessServices/create', service),
    details: (id: string) => requests.get<Result<WirelessService>>(`/WirelessServices/${id}`),
    update: (service: WirelessService) => requests.put<Result<WirelessService>>(`/WirelessServices/update/${service.id}`, service),
    delete: (id: string) => requests.del<Result<string>>(`/WirelessServices/${id}`),
    downloadImportFileSample: () => requests.get<Blob>(`/WirelessServices/download-import-file-sample`,{responseType: 'blob'}),
    importServicesFromFile: (importFile: FormData) => requests.post<Result<string>>(`/WirelessServices/import-services-from-file`,importFile),
}

const WirelessUsages = {
    search: (params: SearchParams) => requests.post<PaginatedResult<WirelessUsage>>(`/WirelessUsage/WirelessUsageListPaginated`, params), 
    delete: (id: string) => requests.del<Result<string>>(`/WirelessUsage/${id}`),
    downloadImportFileSample: () => requests.get<Blob>(`/WirelessUsage/download-usage-import-file-sample`,{responseType: 'blob'}),
    importUsageFromFile: (importFile: FormData) => requests.post<Result<string>>(`/WirelessUsage/import-usage-from-file`,importFile),
}

const Policies = {
    search: (params: SearchParams) => requests.post<PaginatedResult<Policy>>(`/policy/PolicyListPaginated`, params), // paginated list handled server-side
    create: (policy: AddPolicyRequest) => requests.post<Result<Policy>>('/policy/create',policy),
    details: (id: string) => requests.get<Result<Policy>>(`/policy/${id}`),
    update: (policy: Policy) => requests.put<Result<Policy>>(`/policy/update/${policy.id}`, policy),
    delete: (id: string) => requests.del<Result<string>>(`/policy/${id}`),
}

const ComplianceEvents = {
    search: (params: ComplianceEventSearchParams) => requests.post<PaginatedResult<ComplianceEvent>>(`/ComplianceEvents/ComplianceEventListPaginated`, params), 
    details: (id: string) => requests.get<Result<ComplianceEvent>>(`/ComplianceEvents/${id}`),
    update: (record: UpdateComplianceEvent) => requests.put<Result<ComplianceEvent>>(`/ComplianceEvents/update/${record.id}`, record),
    getUsersForAssignList: () => requests.get<UserEventAssignmentOption[]>('/ComplianceEvents/get-users-for-event-assignment')
}

const Tags = {
    search: (params: SearchParams) => requests.post<PaginatedResult<Tag>>(`/tags/TagListPaginated`, params), 
    create: (tag: AddTagRequest) => requests.post<Result<Tag>>('/tags/create',tag),
    details: (id: string) => requests.get<Result<Tag>>(`/tags/${id}`),
    update: (tag: Tag) => requests.put<Result<Tag>>(`/tags/update/${tag.id}`, tag),
    delete: (id: string) => requests.del<Result<string>>(`/tags/${id}`),
    deleteTaggedItem: (id: string) => requests.del<Result<string>>(`/tags/deleteTaggedItem/${id}`),
    searchItems: (params: SearchParamsTaggedItems) => requests.post<PaginatedResult<TaggedItem>>(`/tags/TaggedItemListPaginated`, params), 
    downloadImportTaggedItemFileSample: (tagId: string) => requests.get<Blob>(`/tags/download-import-tagged-items-file-sample/${tagId}`,{responseType: 'blob'}),
    importTaggedItemFromFile: (importFile: FormData, tagId: string) => requests.post<Result<string>>(`/tags/import-tagged-items-from-file/${tagId}`, importFile),
}

const UsageInsights = {
    loadData: (params: UsageInsightsFilter) => requests.post<Result<UsageInsightsData>>(`/UsageInsights/load-data`, params), 
    getFilterOptions: () => requests.get<UsageInsightsFilterOptions>(`/UsageInsights/get-filter-options`), 
}

const CostInsights = {
    loadData: (params: UsageInsightsFilter) => requests.post<Result<CostInsightsData>>(`/CostInsights/load-data`, params), 
    getFilterOptions: () => requests.get<UsageInsightsFilterOptions>(`/UsageInsights/get-filter-options`), 
}

const ComplianceInsights = {
    loadData: (params: ComplianceInsightsFilter) => requests.post<Result<ComplianceInsightsData>>(`/ComplianceInsights/load-data`, params), 
    getFilterOptions: () => requests.get<ComplianceInsightsFilterOptions>(`/ComplianceInsights/get-filter-options`), 
}

const DashboardInsights = {
    loadData: () => requests.get<Result<LandingDashboardInsightsData>>(`/DashboardInsights/load-data`),
}

const SubscriptionManagement = {
    createCheckoutSession: (checkoutData: CheckoutData) => requests.post<Result<string>>(`/Subscription/create-checkout-session`, checkoutData),
    getSubscriptionPlans: () => requests.get<Result<SubscriptionPlan[]>>(`/Subscription/get-subscription-plans`), 
    getCurrentSubscription: () => requests.get<Result<CurrentSubscription>>(`/Subscription/get-current-subscription`), 
    cancelSubscription: () => requests.post<Result>(`/Subscription/cancel-subscription`, {}), 
    getSubscriptionPaymentDetails: () => requests.get<Result<SubscriptionPaymentDetails>>(`/Subscription/get-subscription-payment-details`), 
}

const agent = {
    Account,
    Users,
    Tenants,
    Employees,
    CompanyInfo,
    WirelessServices,
    WirelessUsages,
    Policies,
    ComplianceEvents,
    Tags,
    UsageInsights,
    CostInsights,
    ComplianceInsights,
    DashboardInsights,
    SubscriptionManagement
}

export default agent;